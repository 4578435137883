import './App.css';
import AboutSection from './components/AboutSection';
import BannerSection from './components/BannerSection';
import CasinoGamesection from './components/CasinoGameSection';
import ContactUsSection from './components/ContactUsSection';
import Footer from './components/Footer';
import OurGameSection from './components/OurGameSection';
import SportsSection from './components/SportsSection';
import StepsSection from './components/StepsSection';
function App() {
  return (
    <div className="App">
      <BannerSection />
      <AboutSection />
      <SportsSection />
      <StepsSection />
      <CasinoGamesection />
      <OurGameSection />
      <ContactUsSection />
      <Footer />
    </div>
  );
}

export default App;
