import img1 from "../assets/Images/img-1.png";
import img2 from "../assets/Images/img-2.png";
import img3 from "../assets/Images/img-3.png";
export default function CasinoGamesection() {
  return (<section class="casino-section">
    <h2>CASINO</h2>
    <div class="casino-big-box">
      <div class="casino-content-box">
        <img src={img3} alt="" class="casino-game-img" loading="lazy" />
      </div>
      <div class="casino-content-box">
        <div class="casino-content">
          <h3>Play Now</h3>
          <p>Live casino games on the Betexch website feature a variety of entertaining games that are
            specifically designed for Indian players.</p>
        </div>
        <img src={img2} alt="" class="casino-game-img" loading="lazy" />
      </div>
      <div class="casino-content-box">
        <img src={img1} alt="" class="casino-game-img" loading="lazy" />
      </div>
    </div>
    <a href="https://wa.me/+919085400004" ><button className="secondary-button">PLAY NOW</button></a>
  </section>)
}