import step1 from "../assets/Images/1.png";
import step2 from "../assets/Images/2.png";
import step3 from "../assets/Images/3.png";
import step4 from "../assets/Images/4.png";
import t4g from "../assets/Images/T4gatZ.jpg";
import below from "../assets/Images/below-design.png";

export default function StepsSection() {
  return (<section class="about-section">
    <h2>Play Now With Betexch</h2>
    <div class="about-content-box">
      <div class="about-content-box-1">
        <img src={t4g} alt="" loading="lazy" />
      </div>
      <div class="about-content-box-2">
        <p>Betexch is India's leading gaming and betting exchange platform, offering over 5,000 games, including virtual sports like cricket, tennis, and horse racing. As the top live casino provider in the industry, Betexch features popular games such as Aviator, Teen Patti, Roulette, Blackjack, and JetX. To join, simply contact us via WhatsApp to register with Betexch.
        </p>
        <h3>HOW DOES IT WORK</h3>
        <div class="about-how-work-box-2">
          <div class="about-how-work-box-content-2">
            <p class="step-text">STEP <img src={step1} alt="" loading="lazy" /></p>
            <div class="about-step-content">
              <h4>Create Account</h4>
              <p>Contact us on WhatsApp for registration on Betexch</p>
            </div>
          </div>
          <div class="about-how-work-box-content-2">
            <p class="step-text">STEP <img src={step2} alt="" loading="lazy" /></p>
            <div class="about-step-content">
              <h4>Payment</h4>
              <p>Add funds to your Betexch wallet</p>
            </div>
          </div>
        </div>
        <div class="about-how-work-box-2">
          <div class="about-how-work-box-content-2">
            <p class="step-text">STEP <img src={step3} alt="" loading="lazy" /></p>
            <div class="about-step-content">
              <h4>Get ID</h4>
              <p>We'll provide you with a unique ID and password</p>
            </div>
          </div>
          <div class="about-how-work-box-content-2">
            <p class="step-text">STEP <img src={step4} alt="" loading="lazy" /></p>
            <div class="about-step-content">
              <h4>Start Playing</h4>
              <p>Enjoy 5000+ games on Betexch</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <img src={below} class="below-design-img" alt="" loading="lazy" />
  </section>)
}