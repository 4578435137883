import g1 from "../assets/Images/g-1.jpg";
export default function OurGameSection() {
  return (<section class="our-new-games">
    <h2>OUR GAMES</h2>
    <div class="our-new-games-box">
      <img src="https://cdn.royalgaming.online/rg_roulette.jpg" alt="" loading="lazy" />
      <img src="https://cdn.dreamcasino.live/gap_casino/teen_patti_227100.png" alt="" loading="lazy" />
      <img src={g1} alt="" loading="lazy" />
      <img src="https://cdn.dreamcasino.live/sms_jetx/thumb.jpg" alt="" loading="lazy" />
    </div>
    <div class="our-new-games-box">
      <img src="https://cdn.royalgaming.online/rg_7up_7down.jpg" alt="" loading="lazy" />
      <img src="https://rg-assests.royalgaming.online/lobby-icon/Amar%20Akbar%20Anthony.png" alt="" loading="lazy" />
      <img src="https://cdn.royalgaming.online/rg_dragon_tiger.jpg" alt="" loading="lazy" />
      <img src="https://cdn.dreamcasino.live/rg_lucky7.webp" alt="" loading="lazy" />
    </div>
    <a href="https://wa.me/+919085400004"><button className="secondary-button">PLAY NOW</button></a>
  </section>)
}