import wpIcon from "../assets/Images/whatsapp-icon.png";
export default function ContactUsSection() {
  return (
    <section class="contact-us-section">
      <h2>CONTACT US</h2>
      <p>Betexch is India's leading licensed online casino and betting services provider. With over 5,000 game
        options on our site, we have one of the largest game selections in the industry. Contact our WhatsApp team
        today to get your ID card and become part of our ongoing success. You will also receive a 10% welcome bonus
        upon successful registration.</p>
      <a href="https://wa.me/+919085400004">
        <button>CHAT WITH US <img src={wpIcon} alt="" loading="lazy" /></button>
      </a>
      <p class="contact-para">By all means, contact us. We are always listening...</p>
    </section>
  )
}