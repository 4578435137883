import rohitImage from "../assets/Images/rohit-transformed.jpeg";
import banner1 from "../assets/Images/Banner01.jpeg.jpg";
import banner2 from "../assets/Images/Banner02.jpeg.png";
import banner3 from "../assets/Images/Banner03.jpeg.jpg";
import belowDesign from "../assets/Images/below-design.png";
import secureSvg from "../assets/Images/secure-gdpr-user-svgrepo-com.svg";
import workSvg from "../assets/Images/work-is-money-svgrepo-com.svg";
import bestSvg from "../assets/Images/gold-medal-3-svgrepo-com.svg";
export default function AboutSection() {
  return (<section class="about-section">
    <h2>About Betexch</h2>
    <div class="about-content-box">
      <div class="about-content-box-1">
        <img src={rohitImage} alt="" loading="lazy" />
      </div>
      <div class="about-content-box-2">
        <p>Betexch is India's largest gaming and betting exchange provider with over 5000 games including
          virtual sports like cricket, tennis and horse racing. Betexch is currently the best live casino
          site in the industry, offering popular casinos such as Aviator, Teen Patti, Roulette, Black Jack,
          and JetX. Contact us via WhatsApp to register for Betexch.
        </p>
        {/* <!-- <h3>HOW DOES IT WORK</h3> --> */}
        <div class="about-how-work-box">
          <div class="about-how-work-box-content">
            {/* <!-- <p class="step-text">STEP <img src="./images/1.png" alt=""></p> --> */}
            <div class="about-step-content">
              <h4><img src={bestSvg} height={"40px"} width={"40px"} /> BEST FANTASY PROVIDER</h4>
              <p>We provide the best fantasy sports experience to our players who want to play games with their skills.</p>
            </div>
          </div>
          <div class="about-how-work-box-content">
            {/* <!-- <p class="step-text">STEP <img src="./images/2.png" alt=""></p> --> */}
            <div class="about-step-content">
              <h4> <img src={workSvg} height={"40px"} width={"40px"} /> WORKING SINCE 2008</h4>
              <p>We have been working in this field since 2008, so we have a lot more experience than any other newcomers.</p>
            </div>
          </div>
          <div class="about-how-work-box-content"></div>
          {/* <!-- <p class="step-text">STEP <img src="./images/3.png" alt=""></p> --> */}
          <div class="about-step-content">
            <h4>
              <img src={secureSvg} height={"40px"} width={"40px"} /> HIGH SECURITY</h4>
            <p>Security is our top priority, and your data and information are safe and not shared at any point!</p>
          </div>
        </div>
      </div>
    </div>
    {/* <!-- About Image Box --> */}

    <div class="about_image_box">
      <img src={banner2} alt="Image 2" loading="lazy" />
      <img src={banner1} alt="Image 1" loading="lazy" />
      <img src={banner3} alt="Image 3" loading="lazy" />
    </div>

    <img src={belowDesign} class="below-design-img" alt="" loading="lazy" />
  </section>)
}