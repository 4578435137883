import q1Img from "../assets/Images/q1.jpg";
import q3 from "../assets/Images/q3.jpg";
import q4 from "../assets/Images/q4.jpg";
import q5 from "../assets/Images/q5.png";
export default function SportsSection() {
  return (<section class="sports-section">
    <div class="cricket_box_img_container">
      <h2>All Cricket Series ID Available</h2>
      <div class="cricket_box_container">
        <div class="cricket_box_img_box">
          <img src={q1Img} alt="ss" loading="lazy" />
        </div>
        {/* <!-- <div class="cricket_box_img_box">
          <img src="./images/q2.jpeg" alt=""/>
        </div> --> */}
        <div class="cricket_box_img_box">
          <img src={q3} alt="" loading="lazy" />
        </div>
        <div class="cricket_box_img_box">
          <img src={q4} alt="" loading="lazy" />
        </div>
        <div class="cricket_box_img_box">
          <img src={q5} alt="" loading="lazy" />
        </div>
      </div>
    </div>
  </section>)
}