
import logoImage from "../assets/Images/LOGO-2.webp";
import wpIcon from "../assets/Images/whatsapp-icon.png";
export default function BannerSection() {
  return (<section class="banner-section">
    <div class="banner-content-section">
      {/* <!-- <h2>SHYAM<span>PLAY</span></h2> --> */}
      <img src={logoImage} alt="" class="logo-img" loading="lazy" />
      <p>Get a 10% bonus on your first deposit with Betexch, the official website for sports betting and
        online
        casino games.</p>
      <div class="banner-inner-content-box">
        <a
          href="https://wa.me/+919085400004"
        >
          <button>Get Your ID NOW! <img src={wpIcon} alt="" loading="lazy" /></button>
        </a>
        <h3>Whatsapp Us Today <br /><span>+91 9085400004</span></h3>
      </div>
    </div>
  </section>)
}