import railImage from "../assets/Images/rails-img.png";
import wpSvg from "../assets/Images/whatsapp-svgrepo-com.svg";
import telegramSvg from "../assets/Images/telegram-svgrepo-com.svg";
import instaSvg from "../assets/Images/instagram-svgrepo-com.svg";
export default function Footer() {
  return (
    <footer>
      <img src={railImage} alt="" class="rails-img" loading="lazy" />
      <div class="footer-social-bar">
        <h2>Stay Connected With Us</h2>
        <p>Stay ahead of the market by connecting through our socials.</p>

        <section class="social-wrapper">
          <a href="https://wa.me/+919085400004" class="social-button">
            <div class="social-icon">
              {/*  */}
              <img src={wpSvg} height={20} width={20} loading="lazy" />
              {/* <i class="fab fa-whatsapp"></i> */}
            </div>

            <span>WhatsApp</span>
          </a>

          <a href="https://t.me/betexchangeofficial" class="social-button">
            <div class="social-icon">
              <img src={telegramSvg} height={20} width={20} loading="lazy" />
              {/* <i class="fab fa-telegram"></i> */}
            </div>
            <span>Telegram Group</span>
          </a>

          <a href="https://t.me/betexchangeofficial" class="social-button">
            <div class="social-icon">
              {/* <i class="fab fa-telegram"></i> */}
              <img src={telegramSvg} height={20} width={20} loading="lazy" />
            </div>
            <span>Telegram Channel</span>
          </a>

          <a href="https://www.instagram.com/bet.exchangeofficial" class="social-button">
            <div class="social-icon">
              {/* <i class="fab fa-instagram"></i> */}
              <img src={instaSvg} height={20} width={20} loading="lazy" />
            </div>
            <span>Instagram</span>
          </a>

        </section>
      </div>
      <p class="copyright-text">Copyright © 2024 by Betexch</p>
    </footer>
  )
}